import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`This is the roadmap for the Carbon Design System, based on our planned design and development path. It is a list of our priorities in various broad initiative areas.`}</p>
    <h3 {...{
      "id": "core-product"
    }}>{`Core product`}</h3>
    <ul>
      <li parentName="ul">{`WCAG Compliance for core component libraries (vanilla and React)`}</li>
      <li parentName="ul">{`Sketch kit themes`}</li>
      <li parentName="ul">{`Data table functionality updates`}</li>
      <li parentName="ul">{`Properties and state management`}</li>
      <li parentName="ul">{`UI Shell v1`}</li>
      <li parentName="ul">{`Design specs for unbuilt features`}</li>
      <li parentName="ul">{`New feature requests`}</li>
      <li parentName="ul">{`Theming`}</li>
      <li parentName="ul">{`Document and templatize the pattern design process`}</li>
      <li parentName="ul">{`Charts`}</li>
      <li parentName="ul">{`Data visualization`}</li>
      <li parentName="ul">{`Investigate feasibility / desirability of fluid components`}</li>
    </ul>
    <h3 {...{
      "id": "process-improvement"
    }}>{`Process improvement`}</h3>
    <ul>
      <li parentName="ul">{`Sketch kit themes functionality`}</li>
      <li parentName="ul">{`Dev tooling`}</li>
      <li parentName="ul">{`Generate design assets from code`}</li>
      <li parentName="ul">{`Tools to measure Carbon adoption`}</li>
    </ul>
    <h3 {...{
      "id": "education-and-activation"
    }}>{`Education and activation`}</h3>
    <ul>
      <li parentName="ul">{`‘Adopt a product’ initiative`}</li>
      <li parentName="ul">{`Class curriculum coursework`}</li>
      <li parentName="ul">{`More tutorials`}</li>
      <li parentName="ul">{`User testing and research`}</li>
      <li parentName="ul">{`Expand enablement and education for contributing`}</li>
    </ul>
    <h3 {...{
      "id": "governance-and-architecture"
    }}>{`Governance and architecture`}</h3>
    <ul>
      <li parentName="ul">{`React as the source of truth, or introduce monorepo approach`}</li>
      <li parentName="ul">{`Standardize components (specs, reporting, etc)`}</li>
      <li parentName="ul">{`Define add-ons / user generated content approach`}</li>
    </ul>
    <h3 {...{
      "id": "support"
    }}>{`Support`}</h3>
    <ul>
      <li parentName="ul">{`Clean up code comments`}</li>
      <li parentName="ul">{`Expanded documentation and live demo`}</li>
      <li parentName="ul">{`Define active support model / strategy`}</li>
    </ul>
    <h2 {...{
      "id": "more-information-on-patterns"
    }}>{`More Information on Patterns`}</h2>
    <p>{`Prioritize the most important compound components, layouts, and workflow patterns. Encourage bringing these in through contribution from extended teams.`}</p>
    <ul>
      <li parentName="ul">{`Publish on the website the full list of `}<strong parentName="li">{`all`}</strong>{` components that we propose, even if 0% complete.`}</li>
      <li parentName="ul">{`Prioritize and solicit contributions based on follows and upvotes for components.`}</li>
    </ul>
    <h4 {...{
      "id": "compound-components"
    }}>{`Compound components`}</h4>
    <ul>
      <li parentName="ul">{`Shell (header, global navigation, avatar, left/right sidebars, breadcrumbs, footer)`}</li>
      <li parentName="ul">{`Forms`}</li>
      <li parentName="ul">{`Data table`}</li>
      <li parentName="ul">{`Empty states`}</li>
      <li parentName="ul">{`Date picker`}</li>
      <li parentName="ul">{`Cards`}</li>
      <li parentName="ul">{`Condition builder`}</li>
      <li parentName="ul">{`Reporting`}</li>
      <li parentName="ul">{`File uploader`}</li>
      <li parentName="ul">{`Calendar/scheduler`}</li>
      <li parentName="ul">{`Data visualization`}</li>
    </ul>
    <h4 {...{
      "id": "page-templates"
    }}>{`Page templates`}</h4>
    <ul>
      <li parentName="ul">{`Home page`}</li>
      <li parentName="ul">{`Dashboard page`}</li>
      <li parentName="ul">{`Detail page`}</li>
      <li parentName="ul">{`Profile page`}</li>
      <li parentName="ul">{`Catalog page`}</li>
      <li parentName="ul">{`Billing and usage page`}</li>
      <li parentName="ul">{`Management page`}</li>
      <li parentName="ul">{`Docs page`}</li>
      <li parentName="ul">{`Legal`}</li>
    </ul>
    <h4 {...{
      "id": "workflow-patterns"
    }}>{`Workflow patterns`}</h4>
    <ul>
      <li parentName="ul">{`Login`}</li>
      <li parentName="ul">{`First-time use`}</li>
      <li parentName="ul">{`Registration`}</li>
      <li parentName="ul">{`Searching`}</li>
      <li parentName="ul">{`What’s new`}</li>
      <li parentName="ul">{`Embedded help`}</li>
      <li parentName="ul">{`Provisioning`}</li>
      <li parentName="ul">{`Upgrading`}</li>
      <li parentName="ul">{`Updating`}</li>
      <li parentName="ul">{`Canceling`}</li>
      <li parentName="ul">{`Favoriting`}</li>
    </ul>
    <h2 {...{
      "id": "disclaimer"
    }}>{`Disclaimer`}</h2>
    <p>{`This roadmap is not a delivery commitment and is subject to change based on user needs and feedback.`}</p>
    <p><em parentName="p">{`Last updated 4/22/2019`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      